<template>
  <div>
    <search-product-sale @submit="submitSearch" @download="download" ref="searchForm" />
    <a-row :gutter="24" style="background-color: #e1e1e1">
      <a-col :span="3">
        <a-descriptions
          :column="1"
        >
          <span>产品类型</span>
          <span>销售个数</span>
          <span>销售金额(元)</span>
        </a-descriptions>
      </a-col>
      <a-col :span="index < 3 ? 2 : 3" v-for="(item,index) in dataSum" :key="index">
        <a-descriptions
          :column="1"
        >
          <span> {{ item.type_name }}</span>
          <span> {{ item.count }}</span>
          <span>{{ item.fee }}</span>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showDetail(record)">查看详情</a>
        </a-space>
      </span>
    </a-table>
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
    <specification-detail
      v-if="isShowDetail"
      :visible.sync="isShowDetail"
      :date-begin="beginTime"
      :date-end="endTime"
      :sum-data="oneRecord"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchProductSale from '@/views/fee_data/product_sale/Search'
import { downloadProductSaleStatistics, findProductSaleStatistics } from '@/api/product_statistic'
import { downLoadExcel } from '@/utils/downLoadExcel'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'OrderFeeDetails',
  components: {
    Pagination,
    SearchProductSale,
    SpecificationDetail: () => import('@/views/fee_data/product_sale/SpecificationDetail')
  },
  data() {
    return {
      isShowDetail: false, // 是否显示详情弹窗
      query: {},
      data: [],
      loading: true,
      previewVisible: false,
      pagination: {
        total_count: 0
      },
      dataSum: [],
      beginTime: '',
      endTime: '',
      oneRecord: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '产品类型',
          dataIndex: 'product_type_display',
          width: 100,
          fixed: 'left'
        },
        {
          title: '产品名称',
          width: 150,
          dataIndex: 'product_name',
          fixed: 'left'
        },
        {
          title: '产品状态',
          width: 100,
          dataIndex: 'product_status_display'
        },
        {
          title: '项目代码',
          width: 100,
          dataIndex: 'product_code'
        },
        {
          title: '拼音码',
          width: 100,
          dataIndex: 'product_pinyin'
        },
        {
          title: '产品规格',
          width: 130,
          dataIndex: 'product_specification_name'
        },
        {
          title: '规格状态',
          width: 100,
          dataIndex: 'specification_status_display',
        },
        {
          title: '销售数量',
          width: 100,
          dataIndex: 'count',
        },
        {
          title: '销售金额(元)',
          width: 130,
          dataIndex: 'fee',
          customRender: formatCurrency
        },
        {
          title: '订单明细',
          width: 180,
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    download() {
      downloadProductSaleStatistics(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          var data = []
          for (const d of res.data) {
            delete d.specification_id
            data.push(d)
          }
          const header = `产品类型,产品名称,产品状态,项目代码,拼音码,产品规格,规格状态,销售数量,销售金额（元）\n`
          downLoadExcel(header, data, '产品销售统计')
        }
      })
    },

    fetchData() {
      this.loading = true
      findProductSaleStatistics(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagination = res.pagination
          this.dataSum = res.data.sum
        }
        this.loading = false
      })
    },
    showDetail(record) {
      console.log(this.query)
      this.beginTime = this.query.date_begin
      this.endTime = this.query.date_end
      this.oneRecord = record
      this.isShowDetail = true
    }
  }
}
</script>
