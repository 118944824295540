<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="12" :xxl="8">
          <a-form-item label="销售时间">
            <a-range-picker
              :placeholder="['开始时间', '结束时间']"
              style="width: 100%;"
              v-decorator="['created_at']"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6" :xxl="4">
          <a-form-item label="产品类型">
            <a-select
              show-search
              v-decorator="['product_type']"
              placeholder="请选择产品类型"
              @focus="fetchProductTypeOptions"
              @load="loadingProductTypeOptions"
              allow-clear
              @change="handleChangeProductType"
              :filter-option="filterOption"
              option-filter-prop="children"
            >
              <a-select-option
                v-for="productType in productTypeOptions"
                :key="productType.id"
                :value="productType.slug"
              >
                {{ productType.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="产品名称">
            <a-select
              show-search
              v-decorator="['product_id']"
              placeholder="请选择产品名称"
              @load="loadingProductOptions"
              allow-clear
              :filter-option="filterOption"
              option-filter-prop="children"
              @change="handleChangeProduct"
            >
              <a-select-option
                v-for="product in productOptions"
                :key="product.id"
                :value="product.id"
              >
                {{ product.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="产品规格">
            <a-select
              show-search
              v-decorator="['product_specification_id']"
              placeholder="请选择产品规格"
              @load="loadingProductSpecificationOptions"
              allow-clear
              :filter-option="filterOption"
              option-filter-prop="children"
            >
              <a-select-option
                v-for="spec in productSpecificationOptions"
                :key="spec.id"
                :value="spec.id"
              >
                {{ spec.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="订单来源">
            <a-select
                show-search
                v-decorator="['order_source']"
                placeholder="请选择订单来源"
                @load="loadingOrderSourceOptions"
                allow-clear
                :filter-option="filterOption"
                option-filter-prop="children"
                @focus="fetchOrderSourceOptions"
            >
              <a-select-option
                  v-for="source in orderSourceOptions"
                  :key="source.value"
                  :value="source.value"
              >
                {{ source.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button type="primary" @click="download">
                下载
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDateTime, formatRangeEndDateTime } from '@/utils/time'
import { getProductType } from '@/api/product_type'
import { findTenantProductsOptions } from '@/api/product'
import { findProductSpecificationOptions } from '@/api/product_specification'
import { findOrderSourceOptions } from '@/api/order'

export default {
  name: 'SearchProductSale',
  data() {
    return {
      productTypeOptions: [],
      productOptions: [],
      productSpecificationOptions: [],
      orderSourceOptions: [],
      loadingProductTypeOptions: false,
      loadingProductOptions: false,
      loadingProductSpecificationOptions: false,
      loadingOrderSourceOptions: false,
      form: this.$form.createForm(this, { name: 'product_sale_search' })
    }
  },
  created() {
  },
  methods: {
    fetchProductTypeOptions() {
      this.loadingProductTypeOptions = true
      getProductType().then((res) => {
        if (res.code === '0') {
          this.productTypeOptions = res.data
        }
        this.loadingProductTypeOptions = false
      })
    },
    fetchOrderSourceOptions() {
      this.loadingOrderSourceOptions = true
      findOrderSourceOptions().then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.orderSourceOptions = res.data
          console.log(this.orderSourceOptions)
        }
        this.loadingOrderSourceOptions = false
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChangeProductType(e) {
      this.form.setFieldsValue({ product_id: undefined })
      this.fetchProductOptions(e)
      this.handleChangeProduct(undefined)
    },
    fetchProductOptions(productType) {
      this.loadingProductOptions = true
      this.productOptions = []
      if (productType) {
        findTenantProductsOptions({ product_type_slug: productType, with_delete: true }).then((res) => {
          if (res.code === 0) {
            this.productOptions = res.data
          }
        })
      }
      this.loadingProductOptions = false
    },
    handleChangeProduct(e) {
      this.form.setFieldsValue({ product_specification_id: undefined })
      this.fetchProductSpecificationOptions(e)
    },
    fetchProductSpecificationOptions(productId) {
      this.loadingProductSpecificationOptions = true
      this.productSpecificationOptions = []
      if (productId) {
        findProductSpecificationOptions({ product_id: productId, with_delete: true }).then((res) => {
          if (res.code === 0) {
            this.productSpecificationOptions = res.data
          }
        })
      }
      this.loadingProductSpecificationOptions = false
    },
    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        date_begin: formatRangeBeginDateTime(fieldsValue.created_at),
        date_end: formatRangeEndDateTime(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.productOptions = []
      this.productSpecificationOptions = []
      this.form.resetFields()
      this.$emit('submit', {})
    },
    download(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        date_begin: formatRangeBeginDateTime(fieldsValue.created_at),
        date_end: formatRangeEndDateTime(fieldsValue.created_at)
      }
      this.$emit('download', {})
    }
  }
}
</script>
